import {httpClient} from '@/httpClient';

async function findBrandsByPrefix(prefix: string, page = 0) {
  return await httpClient.get(`/brand?prefix=${prefix}&page=${page}&size=100&sort=brand,asc`).then((response: any) => response.data.content)
}

async function findByPrefix(direction: string, prefix: string, page = 0) {
  return await httpClient.get(`/brand?direction=${direction.toUpperCase()}&page=${page}&prefix=${prefix}&size=100&sort=brand,asc`).then((response: any) => response.data)
}

async function findBrandsByPrefixForCatalogBrands(direction: string, payload: string, page = 0) {
  return await httpClient.get(`/brand/${direction}?page=${page}&prefix=${payload}&size=100&sort=brand,asc`).then((response: any) => response.data)
}

function findCmAndOeBrands(page: string | number, direction: string) {
  return httpClient.get(`/brand/${direction}?page=${page}&size=100&sort=brand,asc`).then(response => response.data)
}

function updateBrand(id: string | number, payload: any) {
  return httpClient.put(`/brand/${id}`, payload)
}

function addNewBrand(payload: object) {
  return httpClient.post('/brand', payload)
}

function setFocusBrand(id: number) {
  return httpClient.patch(`/brand/focus/${id}`)
}

async function findByPrefixCmAndOeBrand(name: any, page = 0) {
  const cmBrands = await findByPrefix('cm', name, page)
  const oeBrands = await findByPrefix('oe', name, page)

  return [...cmBrands.content, ...oeBrands.content]
}

async function exportBrandsByDirection(direction: "CM" | 'OE' = "CM") {
  return httpClient.get(`brand/export?direction=${direction}`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

export default {
  exportBrandsByDirection,
  findByPrefixCmAndOeBrand,
  findBrandsByPrefix,
  findCmAndOeBrands,
  updateBrand,
  addNewBrand,
  setFocusBrand,
  findBrandsByPrefixForCatalogBrands,
  findByPrefix
};
