import {httpClient} from "@/httpClient";

// Поиск товарной группы по имени
function findProductGroupByName(payload: string) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group?descLike=${payload}&size=1000`).then(response => response.data)
}

// Карточка артикула (поиск подгруппы по имени)
function findProductSubGroupByName(payload: string) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/sub?descLike=${payload}`).then(response => response.data)
}

function findAllProductSubGroupByProductId(productId: any) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/sub/${productId}?descLike=`).then(response => response.data.content)
}

function findProductSubSubGroupByNameAndProductId(payload: string, id: any) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/sub/sub/${id}?descLike=${payload}`).then(response => response.data)
}

function findAllProductSubSubGroupByProductId(productId: any) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/sub/sub/${productId}?descLike=`).then(response => response.data.content)
}

// Карточка артикула (поиск подгруппы по имени и айдишке артикула)
function findProductSubGroupByNameAndProductId(payload: string, id: any) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/sub/${id}?descLike=${payload}`).then(response => response.data)
}

function findAllProductGroups(page?: string | number) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group?page=${page}&size=100&sort=desc,asc`).then(response => response.data)
}

function updateProductGroup(pgId: any, payload: any) {
  return httpClient.put(`/product/${localStorage.getItem('cid')}/group/${pgId}`, payload)
}

function findProductGroupByDesc(desc: string, page: string | number) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group?desc=${desc}&page=${page}&size=100&sort=desc,asc`)
}

function addNewProductGroup(payload: any) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/group`, payload).then(response => response.data)
}

function addNewProductSubGroup(payload: any) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/group/sub`, payload).then(response => response.data)
}

function updateProductSubGroup(id: any, payload: any) {
  return httpClient.put(`/product/${localStorage.getItem('cid')}/group/sub/${id}`, payload)
}

function addNewProductSubSubGroup(payload: any) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/group/sub/sub`, payload).then(response => response.data)
}

function updateProductSubSubGroup(id: any, payload: any) {
  return httpClient.put(`/product/${localStorage.getItem('cid')}/group/sub/sub/${id}`, payload)
}

function changeStatus(id: number | string, status: 'VISIBLE' | 'INVISIBLE') {
  return httpClient.patch(`/product/${localStorage.getItem('cid')}/group/${id}?status=${status}`).then((response) => response.data)
}

function findAll(page?: string | number) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/all?page=${page}&size=100&sort=desc,asc`).then((response) => response.data)
}

function deleteProductGroup(id: number | string) {
  return httpClient.delete(`/product/${localStorage.getItem('cid')}/group/${id}`)
}

function deleteSubProductGroup(id: number | string) {
  return httpClient.delete(`/product/${localStorage.getItem('cid')}/group/sub/${id}`)
}

function deleteSubSubProductGroup(id: number | string) {
  return httpClient.delete(`/product/${localStorage.getItem('cid')}/group/sub/sub/${id}`)
}

function findAllInvisible(productGroupName: string) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/unvisible?descLike=${productGroupName}`).then((response) => response.data)
}

function findByDescEn(desc: string) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/group/en?desc=${desc}`).then((response) => response.data)
}

function setTecdocIds(productGroupId: number | string, tecdocIds: Array<string | number>) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/group/tecdocIds/${productGroupId}`, tecdocIds).then((response) => response.data)
}

function setSubGroupTecdocIds(productGroupId: number | string, tecdocIds: Array<string | number>) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/group/sub/tecdocIds/${productGroupId}`, tecdocIds).then((response) => response.data)
}

function setSubSubGroupTecdocIds(productGroupId: number | string, tecdocIds: Array<string | number>) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/group/sub/sub/tecdocIds/${productGroupId}`, tecdocIds).then((response) => response.data)
}

function searchProductGroupByTecdocIds(tecdocIds: Array<string | number>) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/group/tecdocIds`, tecdocIds).then((response) => response.data)
}

export default {
  findProductGroupByName,
  findProductSubGroupByName,
  findAllProductSubGroupByProductId,
  findProductSubGroupByNameAndProductId,
  findAllProductGroups,
  updateProductGroup,
  findProductGroupByDesc,
  addNewProductGroup,
  addNewProductSubGroup,
  updateProductSubGroup,
  updateProductSubSubGroup,
  addNewProductSubSubGroup,
  findProductSubSubGroupByNameAndProductId,
  findAllProductSubSubGroupByProductId,
  changeStatus,
  findAll,
  deleteProductGroup,
  deleteSubProductGroup,
  deleteSubSubProductGroup,
  findAllInvisible,
  findByDescEn,
  setTecdocIds,
  searchProductGroupByTecdocIds,
  setSubGroupTecdocIds,
  setSubSubGroupTecdocIds
}
